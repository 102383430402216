import { useRouter } from 'next/router'
import { useAmp } from 'next/amp'

import { staticPages } from '@shared/constants/staticPages'
import { checkGlanceLiveScoreView, checkIsGlanceView } from '@shared/utils'
import { setToken } from '@shared/libs/token'
import { getCurrentProps } from '@shared/libs/current-props'
import { allRoutes } from '@shared/constants/allRoutes'

const useLayoutApp = () => {
  const router = useRouter()
  const isAmp = useAmp()
  const { isWebStories } = getCurrentProps()
  // const isStaticPage = staticPages.includes(router.asPath)
  const isStaticPage = staticPages.some((path) => router.asPath.startsWith(path))
  const isMobileWebView = router?.query?.isMobileWebView
  const isGlanceView = checkIsGlanceView(router?.query)
  const isDHView = router?.asPath?.includes('dailyhunt')
  const isGlanceLiveView = checkGlanceLiveScoreView(router)
  const token = router?.query?.token
  const isReels = router?.asPath?.includes(allRoutes.reels)
  if (isMobileWebView && token && token) setToken(token)

  return {
    isAmp,
    isStaticPage,
    isGlanceView,
    isMobileWebView,
    isDHView,
    isGlanceLiveView,
    isWebStories,
    isReels
  }
}
export default useLayoutApp
