import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'

import useLayoutApp from '@shared/hooks/useLayoutApp'
import { IS_GLANCE_MLIB } from '@shared/constants'
import { useCallback } from 'react'

const OnMouseAndScroll = dynamic(() => import('@shared/components/lazyLoad/onMouseAndScroll'))
const StickyAds = dynamic(() => import('@shared/components/ads/sticky'))
const GoogleAnalytics = dynamic(() => import('@shared/components/scripts/googleAnalytics'))
const Glance = dynamic(() => import('@shared/components/ads/glance'))
const GlanceSticky = dynamic(() => import('@shared/components/ads/glanceSticky'))
const AppContent = dynamic(() => import('@shared/components/appContent'))
const GlanceMLib = dynamic(() => import('@shared/components/ads/glanceMLib'))
const MLibSticky = dynamic(() => import('@shared/components/ads/glanceMLib/mLibSticky'), { ssr: false })
const GEMAdCode = dynamic(() => import('@shared/components/ads/gemAdCode'))
// const PopupMain = dynamic(() => import('../ads/popup-main'))
const ComScoreAnalytics = dynamic(() => import('shared/components/scripts/comscore'))
const PopupAds = dynamic(() => import('../ads/popup'))

function AppLayout({ children, bIsHideFooter, bIsHideHeader }) {
  const { isAmp, isStaticPage, isGlanceView, isMobileWebView, isDHView, isGlanceLiveView, isWebStories, isReels } = useLayoutApp()

  const renderAdScripts = useCallback(() => {
    if (isGlanceView) {
      if (!IS_GLANCE_MLIB) {
        return (
          <>
            <Glance />
            <GlanceSticky />
          </>
        )
      } else return null
    } else if (!isAmp && !isDHView) {
      if (isMobileWebView) {
        return (
          <>
            <GEMAdCode />
          </>
        )
      } else if (isReels) {
        return <GEMAdCode />
      } else {
        return (
          <OnMouseAndScroll>
            <GEMAdCode />
            {!isStaticPage && (
              <>
                <StickyAds />
                {/* <PopupMain /> */}
                <PopupAds />
              </>
            )}
          </OnMouseAndScroll>
        )
      }
    } else return null
  }, [isGlanceView, isAmp, isDHView, isMobileWebView, isReels, isStaticPage])

  const renderAnalytics = useCallback(() => {
    if (!isMobileWebView && !isWebStories) {
      return (
        <>
          <GoogleAnalytics />
          <ComScoreAnalytics />
        </>
      )
    }
    return null
  }, [isMobileWebView, isWebStories])

  return (
    <>
      {!isMobileWebView && isGlanceView && IS_GLANCE_MLIB && (
        <>
          <GlanceMLib />
          {!isGlanceLiveView && (
            <OnMouseAndScroll>
              <MLibSticky />
            </OnMouseAndScroll>
          )}
        </>
      )}
      {renderAnalytics()}
      {/* {!isMobileWebView && (
        <OnMouseAndScroll>
        </OnMouseAndScroll>
      )} */}
      <AppContent bIsHideFooter={bIsHideFooter} bIsHideHeader={bIsHideHeader}>
        {children}
      </AppContent>
      {renderAdScripts()}
    </>
  )
}
AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  bIsHideFooter: PropTypes.bool,
  bIsHideHeader: PropTypes.bool
}
export default AppLayout
